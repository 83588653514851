import { createRouter, createWebHashHistory } from "vue-router";
import MainLayout from "./layouts/MainLayout.vue";
import HomePrincipal from "./components/HomePrincipal.vue";
import GestionEquipaje from "./components/GestionEquipaje.vue";
import CentroAyuda from "./components/CentroAyuda.vue";
import RequisitosViaje from "./components/RequisitosViaje.vue";
import NecesidadesEspeciales from "./components/NecesidadesEspeciales.vue";
import ViajeFamiliar from "./components/ViajeFamiliar.vue";
import PoliticasMascotas from "./components/PoliticasMascotas.vue";
import InstuTucional from "./components/InstuTucional.vue";
import WebChe from "./components/WebChe.vue";
import CargaEnvio from "./components/CargaEnvio.vue";

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomePrincipal,
      },
      {
        path: "equipaje",
        component: GestionEquipaje,
      },
      {
        path: "centro-ayuda",
        component: CentroAyuda,
      },
      {
        path: "requisitos-viajar",
        component: RequisitosViaje,
      },
      {
        path: "necesidades-especiales",
        component: NecesidadesEspeciales,
      },
      {
        path: "vuelos-ninos-bebes",
        component: ViajeFamiliar,
      },
      {
        path: "mascotas-bordo",
        component: PoliticasMascotas,
      },
      {
        path: "webcheking",
        component: WebChe,
      },
      {
        path: "centro-intitucional",
        component: InstuTucional,
      },
      {
        path: "carga-envio",
        component: CargaEnvio,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            const topOffset = 140;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - topOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
            resolve({ left: 0, top: offsetPosition });
          } else {
            console.error('Elemento no encontrado:', to.hash);
            resolve({ top: 0 });
          }
        }, 100);
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;