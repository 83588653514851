<template>
    <div class="widget-tamep-wrapper">
        <widget-search-flight ></widget-search-flight>
    </div>
</template>

<script>
export default {
    name: 'WidgetTamepSearch',
    mounted() {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://img.transclicksolutions.com/assets/lib/widget/styles.css';
        link.onload = () => {
            const script = document.createElement('script');
            script.src = 'https://img.transclicksolutions.com/assets/lib/widget/main.js';
            document.body.appendChild(script);
        };
        document.head.appendChild(link);
    }
}
</script>

<style scoped>
.widget-tamep-wrapper {
    width: 100%;
    height: 100%;
}
</style>